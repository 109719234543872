import React from "react";
import Helmet from "react-helmet";
import { Content } from "components/Content";
import { TextBlock } from "components/TextBlock";
import { List } from "components/List";
import { InfoEmail } from "components/Emails";
import { Hero } from "components/Hero";
import { images } from "components/HeroImages";

function SignupPage(p) {
  const title = "Sign up | Status100 Promotions";
  const description =
    "Sign up for cheaper tickets and early access to tickets before anyone else";

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: p.location.href,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero image={images.contact}>
        <h1>Sign up</h1>
      </Hero>
      <Content>
        <iframe
          allowTransparency
          style={{
            display: "block",
            width: "100%",
            maxWidth: 600,
            margin: "0 auto",
            height: 1400,
            border: 0,
            background: "transparent",
          }}
          src="https://docs.google.com/forms/d/e/1FAIpQLSdSvryTz6kwuA0B8n18K8-crNZxN74hUR7dCBA1PLGvx6o9gQ/viewform"
        />
      </Content>
    </React.Fragment>
  );
}

export default SignupPage;
